<template>
    <div class="goods-shoot-content">
        <div class="task-table">
            <div class="table-title">
                <span class="text">任务详情</span>
                <span class="text">数据报告（支持拓展名：rar和zip，文件大小不能超过100M）</span>
            </div>
            <div class="table-body">
                <span class="text">电子商务数据报告制作</span>
<!--                    <span class="text download-materials" @click="downloadMaterials">点击下载</span>-->
                <div class="upload text">
                    <el-button plain onclick="uploadFile.click()">选择文件</el-button>
                    <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                           title="" accept=".zip, .rar"
                           @change="changeFile($event)">
                    <span class="upload-text">{{file_name}}</span>
                </div>
            </div>
        </div>
        <div  style="text-align: center">
            <el-button type="primary" @click="submitBtn" :disabled="submit_text === '上传中'">{{submit_text}}</el-button>
        </div>
    </div>
</template>

<script>
    import  Upload from '@/utils/slice_upload';
    import {collectUp_video, collectMerge, StuCommerceSavaAnswer, StuCommerceGetList} from '@/utils/apis'
    export default {
        name: "GoodsVideoShoot",
        data() {
            return {
                //文件名称
                file_name: '未选择任何文件',
                //分片上传
                uploadInstance: null,
                material: '',

                //提交id
                id: null,

                //提交文字
                submit_text: '提交',

                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
            }
        },
        created() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                let params = {}
                if (!this.isExamOrTrain) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                StuCommerceGetList(params).then((res) => {
                    if (Object.prototype.toString.call(res.data) === '[object Object]') {
                        this.file_name = res.data.file_name
                        this.material = res.data.file_path
                    }
                }).catch((err) => {})
            },
            //上传素材
            changeFile(event) {
                if (event.target.files[0].size / 1024 / 1024 > 100) {
                    return this.$message.warning('上传文件不可大于100M')
                }
                let chunkSize = 1024 * 1024 * 8;
                let file = event.target.files[0];
                this.uploadInstance = new Upload(file, chunkSize, collectUp_video);
                this.file_name = file.name;
                this.submit_text = '上传中';
                let index = 0;
                this.uploadSlice(index);
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    collectMerge({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.submit_text = '提交';
                            this.material = res.data.src;
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.material = res.data.src;
                            this.submit_text = '提交';
                            this.percent = 100;
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //提交
            submitBtn() {
                let params = {
                    op_id: this.$route.query.op_id,
                    file_name: this.file_name,
                    file_path: this.material
                }
                if (this.$route.query.course_id) {
                    params.course_id = this.$route.query.course_id
                }
                StuCommerceSavaAnswer(params).then((res) => {
                    this.$message.success(res.msg);
                    this.getInfo()
                }).catch((err) => {})
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-shoot-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-shoot-header {
            display: flex;
            justify-content: space-between;
            /*align-items: center;*/
            /*height: 58px;*/
            border-bottom: 2px solid #F1F5FF;
            span {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .task-table {
        border: 1px solid #E5E5E5;
        font-size: 16px;
        .table-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: #F6F6F6;
            border-bottom: 1px solid #EEEEEE;
            box-sizing: border-box;
            padding: 0 40px;
            color: #666;
        }
        .upload {
            display: flex !important;
            align-items: center;
        }
        .text {
            width: 1%;
            flex: 1;
        }
        .table-body {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            padding: 0 40px;
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        display: inline-block;
        flex: 1;
        width: 1%;
        color: #666;
        margin-left: 10px;
    }
    .is-plain {
        background: #FFF;
        border-color: #1CB064;
        color: #1CB064;
        border-radius: 2px;
    }
    .download-materials {
        color: #2461EF;
        cursor: pointer;
    }
</style>